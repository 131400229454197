<template>

    <!-- <keep-alive> -->
    <div id="enterSearch" class="list_content">
        <!-- 查询条件 -->
        <el-form v-if="qyzhShow" :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.user_name" placeholder="用户名"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.comp_name" placeholder="单位"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.ks_type" placeholder="科室"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.province_name" placeholder="省份"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.city_name" placeholder="城市"></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-select v-model="formInline.user_level" placeholder="级别" style="width: 120px;">
                    <el-option label="全部" value=0></el-option>
                    <el-option label="超级管理员" value=1></el-option>
                    <el-option label="管理员" value=2></el-option>
                    <el-option label="普通用户" value=3></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="">
                <el-select v-model="formInline.use_status" placeholder="状态" style="width: 80px;">
                    <el-option label="全部" value=0></el-option>
                    <el-option label="启用" value=1></el-option>
                    <el-option label="禁用" value=2></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="">
                <div class="block">
                    <el-date-picker v-model="formInline.create_time" type="datetimerange" align="right"
                        start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:01', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss" style="width: 360px;">
                    </el-date-picker>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="cx_sunbmit">查询</el-button>
            </el-form-item>
        </el-form>
        <!-- 列表内容 -->
        <el-table :data="tableData" :cell-style="{ padding: '2px 0' }" height="634" border stripe>
            <!-- <el-table-column prop="user_id" label="id" align="center" width="100"></el-table-column> -->
            <el-table-column label="关系" width="60" align="center">
                <template slot-scope="scope">
                    <el-button @click="toXs(scope.row)" type="text" size="small">列表</el-button>
                    <!-- <el-button v-if="qyzhShow" @click="toAddXs(scope.row)" type="text" size="small">新增</el-button> -->
                </template>
            </el-table-column>

            <el-table-column prop="user_name" label="用户名" align="center" width="150">
                <template slot-scope="scope">
                    <el-button type="text" @click="toUser(scope.row)">{{ scope.row.user_name }}</el-button>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="comp_name" label="单位名称" show-overflow-tooltip width="120" align="center"></el-table-column> -->
            <el-table-column v-if="qyzhShow" prop="yunying_state" width="80" show-overflow-tooltip label="运营状态">
                <template slot-scope="scope">
                    <!-- 1= 渠道 2=自运营 3= 合作 4=渠道转自运营 5=自运营转渠道 -->
                    <span v-if="scope.row.yunying_state == 1">渠道</span>
                    <span v-if="scope.row.yunying_state == 2" style="color:green">自运营</span>
                    <span v-if="scope.row.yunying_state == 3" style="color:green">合作</span>
                    <span v-if="scope.row.yunying_state == 4" style="color:green">渠转自</span>
                    <span v-if="scope.row.yunying_state == 5">自转渠</span>
                </template>
            </el-table-column>
            <el-table-column v-if="qyzhShow" prop="app_name" label="来源" show-overflow-tooltip width="120"
                align="center"></el-table-column>
            <el-table-column prop="ks_type" label="科室类别" show-overflow-tooltip width="80"
                align="center"></el-table-column>
            <el-table-column prop="province_name" label="省份" show-overflow-tooltip width="120"
                align="center"></el-table-column>
            <el-table-column prop="city_name" label="城市" show-overflow-tooltip width="80"
                align="center"></el-table-column>
            <el-table-column prop="pay_num" width="80" show-overflow-tooltip label="充值条数"></el-table-column>
            <el-table-column prop="allot_num" width="100" show-overflow-tooltip label="已分配条数"></el-table-column>
            <el-table-column prop="remain" width="80" show-overflow-tooltip label="剩余表单">
                <template slot-scope="scope">
                    <span v-if="scope.row.pay_num - scope.row.allot_num <= 5 && scope.row.pay_num - scope.row.allot_num > 0"
                        style="color:orange">{{ scope.row.pay_num - scope.row.allot_num }}</span>
                    <span v-else-if="scope.row.pay_num - scope.row.allot_num > 5"
                        style="color:#37B328">{{ scope.row.pay_num - scope.row.allot_num }}</span>
                    <span v-else-if="scope.row.pay_num - scope.row.allot_num == 0" style="color:red">{{ scope.row.pay_num
        - scope.row.allot_num
}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="msg_pay_num" width="100" show-overflow-tooltip label="剩余短信"></el-table-column>
            <!-- <el-table-column prop="user_level" label="级别" width="100" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.user_level==1">超级管理员</span>
                                        <span v-else-if="scope.row.user_level==2">管理员</span>
                                        <span v-else-if="scope.row.user_level==3">普通会员</span>
                                    </template>
                                </el-table-column> -->
            <el-table-column prop="create_time" width="160" show-overflow-tooltip label="创建时间"></el-table-column>
            <!-- <el-table-column prop="remark" width="120" show-overflow-tooltip label="备注"></el-table-column> -->
            <el-table-column v-if="qyzhShow" prop="use_status" label="用户状态" width="100" align="center">
                <template slot-scope="scope">
                    <el-button type="" v-if="scope.row.use_status == 1" style="color:#37B328"
                        @click="transUseStatus(scope.row, 2)">启用</el-button>
                    <el-button type="" v-else-if="scope.row.use_status == 2" style="color: red"
                        @click="transUseStatus(scope.row, 1)">禁用</el-button>
                </template>
            </el-table-column>

            <el-table-column v-if="qyzhShow" prop="plan_status" label="计划状态" width="100" align="center">
                <template slot-scope="scope">
                    <el-button type="" v-if="scope.row.plan_status == 1" style="color:#37B328"
                        @click="transPlanStatus(scope.row, 2)">启用</el-button>
                    <el-button type="" v-else-if="scope.row.plan_status == 2" style="color: red"
                        @click="transPlanStatus(scope.row, 1)">禁用</el-button>
                </template>
            </el-table-column>

            <el-table-column prop="" label="操作" width="100" align="center">
                <template slot-scope="scope">
                    <el-button type="" style="color:#37B328" @click="updateuser(scope.row)">更新</el-button>
                </template>
            </el-table-column>

        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="current_page" :page-sizes="[15, 20, 25]" :page-size="page_size"
            layout="total, sizes, prev, pager, next, jumper" :total="data_num" :sizes='page_num'>
        </el-pagination>
    </div>
</template>

<script>
import LeftMenu from '@/components/left.vue'
import Top from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'UserListView',
    components: {
        LeftMenu,
        Top,
        Footer
    },
    data() {
        return {
            cache_user: {},
            formInline: {},
            tableData: [],
            current_page: 1, //初始页
            page_size: 15, // 每页的数据
            data_num: 0, // 总条数
            page_num: 0, // 总页数
            userList: [],
            show_liul: '',
            qyzhShow: true,
        }
    },
    methods: {
        cx_sunbmit() {
            this.current_page = 1
            this.onSubmit()
        },
        onSubmit() {
            console.log('开始查询!');
            const that = this
            if (that.formInline.user_level) {
                that.formInline.user_level = Number(that.formInline.user_level)
            }

            if (that.formInline.use_status) {
                that.formInline.use_status = Number(that.formInline.use_status)
            }

            that.formInline.current_page = Number(that.current_page)
            if (that.cache_user.user_level != 1 && that.cache_user.user_level != 2) {
                that.formInline.user_id = that.cache_user.user_id
                that.qyzhShow = false
            }

            this.myAxios.post(that.myBaseurl + '/user/list', that.formInline)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log("submit--res : ", res)
                        that.tableData = res.data.Data.List
                        that.data_num = res.data.Data.TotalCount
                        that.page_num = res.data.Data.TotalPage
                        for (let item of that.tableData) {
                            item.remain = item.pay_num - item.allot_num
                        }
                    } else {
                        alert("查询失败 : " + res.data.Msg)
                    }
                })

            // sessionStorage.setItem('cache_user_page', JSON.stringify(this.formInline))
        },
        // 初始页current_page、初始每页数据数page_size和数据data
        handleSizeChange: function (size) {
            this.page_size = size;
            console.log(this.page_size) //每页下拉显示数据
            this.onSubmit()
        },
        handleCurrentChange: function (current_page) {
            this.current_page = current_page;
            console.log(this.current_page) //点击第几页
            this.onSubmit()
        },

        toUser: function (u) {
            // 加入缓存
            this.$router.push({
                path: '/user/info',
                query: {
                    user_id: u.user_id
                }
            })
        },

        toXs: function (u) {
            // 加入缓存
            sessionStorage.setItem('sec_user', JSON.stringify(u))
            this.$router.push({
                path: '/form/list',
            })
        },

        toAddXs(u) {
            // 加入缓存
            sessionStorage.setItem('sec_user', JSON.stringify(u))
            this.$router.push({
                path: '/form/add/excel',
            })
        },

        transUseStatus: function (row, userStatus) {
            if (confirm('确定修改吗?')) {
                let that = this
                let req = {}
                if (row.user_id && row.user_id != 0) {
                    req.man_level = Number(that.cache_user.user_level)
                    req.user_id = Number(row.user_id)
                    req.use_status = Number(userStatus)
                    this.myAxios.post(that.myBaseurl + '/user/status', req).then(function (res) {
                        if (res.data.Code === '200') {
                            let xgIndex = that.tableData.indexOf(row);
                            if (xgIndex != -1) {
                                that.tableData[xgIndex].use_status = userStatus;
                            }
                            console.log(that.tableData[xgIndex]);
                            alert("修改成功!");
                        } else {
                            alert(res.data.Msg)
                        }
                    })
                } else {
                    alert("为获取到账号!")
                }
            }
        },

        transPlanStatus: function (row, userStatus) {
            if (confirm('确定修改吗?')) {
                let that = this
                let req = {}
                if (row.user_id && row.user_id != 0) {
                    req.man_level = Number(that.cache_user.user_level)
                    req.user_id = Number(row.user_id)
                    req.plan_status = Number(userStatus)
                    this.myAxios.post(that.myBaseurl + '/plan/status', req).then(function (res) {
                        if (res.data.Code === '200') {
                            let xgIndex = that.tableData.indexOf(row);
                            if (xgIndex != -1) {
                                that.tableData[xgIndex].plan_status = userStatus;
                            }
                            console.log(that.tableData[xgIndex]);
                            alert("修改成功!");
                        } else {
                            alert(res.data.Msg)
                        }
                    })
                } else {
                    alert("为获取到账号!")
                }
            }
        },

        updateuser(row) {
            let that = this
            let req = {}
            if (confirm('确定更新当前数据吗?')) {
                if (row.user_id && row.user_id != 0) {
                    req.user_id = Number(row.user_id)
                    this.myAxios.post(that.myBaseurl + '/user/remain/update', req).then(function (res) {
                        alert(res.data.Msg)
                    })
                }
            }
        }
    },

    created() {
        console.log('开始查询!')
        // 从缓存获取user
        this.cache_user = JSON.parse(sessionStorage.getItem('user'))
        this.onSubmit()

    },

    mounted() {
        // 监听回车键查询
        let that = this;
        let enterSearchEle = document.getElementById("enterSearch")

        enterSearchEle.addEventListener("keydown", function (e) {
            console.log(e);
            if (e.key == "Enter") {
                that.$xianliu(that.onSubmit, 500)()
            }
        })
    }
}

</script>

<style lang="less" scoped="scoped">
body {
    margin: 0px;
    padding: 0px;
}

.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    text-align: center;
    width: 100%;
}

.el-table-column {
    line-height: 20px;
}

.el-table__row .hover-row {
    background-color: red !important;
}

a {
    text-decoration: none;
}
</style>
